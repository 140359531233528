import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Analytics } from '@vercel/analytics/react';
import './index.css';
import SignUp from './Authentication/SignUp';
import Login from './Authentication/login';
import ForgotPasword from './Authentication/ForgotPasword';
import Billing from './Authentication/Billing';
import MembershipBilling from './Authentication/MembershipBilling';
import Success from './Authentication/Success';
import Cancel from './Authentication/Cancel';
import Reauth from './Authentication/Reauth';
import StripeOnboarded from './Authentication/StripeOnboarded';

import Dashboard from './Dashboard/Dashboard';
import Invoice from './Dashboard/Invoice/Invoice';
import CreateInvoice from './Dashboard/Invoice/CreateInvoice';
import LoadingPage from './Dashboard/Agreement/loadingPage';
import CreateAgreement from './Dashboard/Agreement/CreateAgreement';
import EditAgreement from './Dashboard/Agreement/EditAgreement';
import PreviewInvoice from './Dashboard/Invoice/PreviewInvoice';

import WalletView from './Wallet/Wallet';
import ProfileView from './Profile/Profile';

import TermsOfService from './TermsOfService';
import PrivacyPolicy from './PrivacyPolicy';
import ShareAgreement from './Dashboard/Agreement/ShareAgreement';
import AutoLogin from './Authentication/AutoLogin';
import StripeOnboarding from './Profile/StripeOnboarding';
import InvoicesList from './Dashboard/InvoicesList';
import { OnboardingProvider } from './Authentication/OnboardingContext';
import OnboardingQuestions from './Authentication/OnboardingQuestions';
import PaymentPage from './Dashboard/Invoice/Invoice';
import ContactUs from './ContactUs';
import mixpanel from 'mixpanel-browser';
import SignaturePage from './Dashboard/Agreement/SignaturePage';
import ClientDashboard from './Dashboard/Clients/ClientDashboard';
import DarkModeButton from './DarkModeButton';
import ClientSignaturePage from './Dashboard/Agreement/ClientSignaturePage';
import LandingPage from './LandingPage/LandingPage';
import SubscriptionStatus from './Dashboard/SubscriptionStatus';
import DownloadBanner from './Dashboard/DownloasAppBanner';
import CompleteProfile from './Authentication/CompleteProfile';
import EditProfile from './Profile/EditProfileModal';
import DownloadSignedAgreement from './Dashboard/Agreement/DownloadSignedAgreement';
import PaymentConfirmation from './Dashboard/Invoice/PaymentConfirmation';

import ProfileEditor from './Profile/ProfileEditor';
import BlogLandingPage from './Blog/BlogLandingPage';
import BlogPost from './Blog/BlogPost';
import SignedDocuments from './Dashboard/SignedDocuments';

import ProjectDashboard from './Dashboard/Project/ProjectDashboard';
import CreateProjectForm from './Dashboard/Project/CreateProject';
import ProjectDetails from './Dashboard/Project/ProjectDetails';
import EditProject from './Dashboard/Project/EditProject';


export default function App() {
  useEffect(() => {
    mixpanel.init('fb208da3b35b1e6af79eeeb1f2a4dd67', {
      debug: process.env.NODE_ENV === 'development',
      track_pageview: true,
      persistence: 'localStorage'
    });
  }, []);

  return (
    <div className="bg-white dark:bg-black text-gray-900 dark:text-white min-h-screen">
      <Router>
        <OnboardingProvider>
          <Analytics />
          <AutoLogin />
          <Routes>
            {/* Blog Related */}
            <Route path="/blog" element={<BlogLandingPage />} />
            <Route path="/blog/:id" element={<BlogPost />} />

            {/* Authentication */}
            <Route path="/" element={<LandingPage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/complete-profile" element={<CompleteProfile />} />
            <Route path="/auth/forgot-password" element={<ForgotPasword />} />
            <Route path="/contact-us" element={<ContactUs />} />

            {/* In App Main Routes */}
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/invoices" element={<InvoicesList />} />
            <Route path="/clients" element={<ClientDashboard />} />
            <Route path="/wallet" element={<WalletView />} />
            <Route path="/profile" element={<ProfileView />} />
            <Route path="/profile/:username" element={<ProfileView />} />
            <Route path="/edit-profile" element={<ProfileEditor />} />

            {/* Stripe Checkout */}
            <Route path="/billing" element={<Billing />} />
            <Route path="/subscription" element={<SubscriptionStatus />} />
            <Route path="/membership-billing" element={<MembershipBilling />} />
            <Route path="/onboarding-questions" element={<OnboardingQuestions />} />

            {/* Agreement Related Routes */}
            <Route path="/create-agreement" element={<CreateAgreement />} />
            <Route path="/share-agreement/:agreementId" element={<ShareAgreement />} />
            <Route path="/loading/:navigateTo" element={<LoadingPage />} />
            <Route path="/signature/:agreementId" element={<SignaturePage />} />
            <Route path="/client-signature/:agreementId" element={<ClientSignaturePage />} />
            <Route path="/download-signed-agreement/:agreementId" element={<DownloadSignedAgreement />} />
            <Route path="/signed-documents" element={<SignedDocuments />} />

            {/* Miscellaneous Routes */}
            <Route path="/success" element={<Success />} />
            <Route path="/cancel" element={<Cancel />} />
            <Route path="/reauth" element={<Reauth />} />

            {/* Stripe-Specific */}
            <Route path="/stripe-ob" element={<StripeOnboarding />} />
            <Route path="/stripe-onboarded" element={<StripeOnboarded />} />

            {/* Invoice and Payment */}
            <Route path="/create-invoice" element={<CreateInvoice />} />
            <Route path="/invoice-details/:invoiceId" element={<Invoice />} />
            <Route path="/preview-invoice/:invoiceId" element={<PreviewInvoice />} />
            <Route path="/payment/:invoiceId" element={<PaymentPage />} />
            <Route path="/payment-confirmation/:invoiceId" element={<PaymentConfirmation />} />

            {/* Project Management */}
            <Route path="/projects" element={<ProjectDashboard />} />
            <Route path="/projects/create" element={<ProjectDashboard showCreate={true} />} />
            <Route path="/project/:projectId" element={<ProjectDetails />} />
            <Route path="/project/:projectId/edit" element={<EditProject />} />

            {/* Legal */}
            <Route path="/tos" element={<TermsOfService />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          </Routes>
          <DarkModeButton />
        </OnboardingProvider>
      </Router>
    </div>
  );
}
