import React, { useState, useEffect } from 'react';
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification, onAuthStateChanged, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import app from '../Firebase';
import { db } from '../Firebase';
import { doc, setDoc } from 'firebase/firestore';
import mixpanel from 'mixpanel-browser';
import ReCAPTCHA from 'react-google-recaptcha';
import { motion } from 'framer-motion';
export default function SignUp() {
  const auth = getAuth(app);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const [captchaValue, setCaptchaValue] = useState(null);
  const [isSent, setIsSent] = useState(false);
  const [verificationMessage, setVerificationMessage] = useState('');

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        navigate('/onboarding-questions');
      }
    });

    return () => unsubscribe();
  }, [auth]);

  const onSendEmailVerification = () => {
    sendEmailVerification(auth.currentUser).then(() => setIsSent(true));
  };

  const handleGoogleSignUp = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      await updateUsernameInDatabase(user.uid, user.displayName);
      mixpanel.track('Sign Up', { 
        'User ID': user.uid,
        'Email': user.email,
        'Username': user.displayName,
        'Method': 'Google',
        'Success': true,
        'Email Domain': user.email.split('@')[1]
      });
      mixpanel.people.set({
        '$email': user.email,
        'Username': user.displayName,
        'Sign Up Method': 'Google',
        'Sign Up Date': new Date().toISOString()
      });
      navigate('/onboarding-questions');
    } catch (error) {
      setErrorMessage(error.message);
      mixpanel.track('Sign Up', {
        'Method': 'Google',
        'Success': false,
        'Error Message': error.message
      });
    }
  };

  async function handleSubmit(event) {
    event.preventDefault();
    const email = event.target.email.value;
    const password = event.target.password.value;
    const username = event.target.username.value;

    if (!captchaValue) {
      setErrorMessage('Please verify the captcha');
      mixpanel.track('Sign Up', {
        'Method': 'Email',
        'Success': false,
        'Error Message': 'Captcha not verified',
        'Email Domain': email.split('@')[1]
      });
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      await sendEmailVerification(user);
      await updateUsernameInDatabase(user.uid, username);
      mixpanel.track('Sign Up', {
        'User ID': user.uid,
        'Email': email,
        'Username': username,
        'Method': 'Email',
        'Success': true,
        'Verification Email Sent': true,
        'Email Domain': email.split('@')[1]
      });
      mixpanel.people.set({
        '$email': email,
        'Username': username,
        'Sign Up Method': 'Email',
        'Sign Up Date': new Date().toISOString()
      });
      setVerificationMessage('Verification email sent. Please check your inbox.');
      navigate('/onboarding-questions');
    } catch (error) {
      setErrorMessage(error.message);
      mixpanel.track('Sign Up', {
        'Method': 'Email',
        'Success': false,
        'Error Message': error.message,
        'Email Domain': email.split('@')[1]
      });
    }
  }

  async function updateUsernameInDatabase(userId, username) {
    const userRef = doc(db, 'users', userId);
    try {
      await setDoc(userRef, {
        username,
        userId,
        isPro: false,
        invoicesCreated: 0,
        agreementsCreated: 0,
        stripeOnboarded: false,
      });
      mixpanel.track('User Profile Created', { 
        'User ID': userId, 
        'Username': username,
        'Success': true
      });
    } catch (error) {
      console.error('Error updating username in database:', error);
      mixpanel.track('User Profile Created', {
        'User ID': userId,
        'Username': username,
        'Success': false,
        'Error Message': error.message
      });
    }
  }

  return (
    <div className="min-h-screen bg-white dark:bg-black flex items-center justify-center p-4 shadow-lg dark:shadow-2xl">
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="w-full max-w-lg bg-white/90 dark:bg-black/90 backdrop-blur-lg rounded-3xl shadow-2xl p-10 space-y-8"
      >
        <div className="text-center">
          <motion.div
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <a href="/">
              <img className="mx-auto h-16 w-auto" src="/klorahLogo.png" alt="Logo" />
            </a>
          </motion.div>
          <h2 className="mt-8 text-4xl font-bold text-gray-900 dark:text-white">
            Join Klorah
          </h2>
          <p className="mt-3 text-gray-600 dark:text-gray-400">
            Already have an account?{' '}
            <a href="/login" className="text-[#5BFF8F] hover:text-[#4BEF7F] font-semibold transition-colors">
              Sign in
            </a>
          </p>
        </div>

        <motion.button
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
          onClick={handleGoogleSignUp}
          className="w-full flex items-center justify-center gap-3 bg-white dark:bg-black hover:bg-gray-50 dark:hover:bg-gray-900 text-gray-900 dark:text-white font-medium py-3 px-4 rounded-xl border border-gray-200 dark:border-gray-700 shadow-sm transition-all duration-200"
        >
          <img src="/googleIcon.svg" alt="Google" className="w-6 h-6" />
          Continue with Google
        </motion.button>

        <div className="relative">
          <div className="absolute inset-0 flex items-center">
            <div className="w-full border-t border-gray-300 dark:border-gray-700"></div>
          </div>
          <div className="relative flex justify-center text-sm">
            <span className="px-2 bg-white/90 dark:bg-black/90 text-gray-500 dark:text-gray-400">Or continue with email</span>
          </div>
        </div>

        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="space-y-4">
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                Email address
              </label>
              <input
                id="email"
                name="email"
                type="email"
                required
                className="mt-1 block w-full px-4 py-3 bg-white dark:bg-black border border-gray-300 dark:border-gray-700 rounded-xl focus:ring-2 focus:ring-[#5BFF8F] focus:border-transparent transition-all duration-200"
                placeholder="you@example.com"
              />
            </div>

            <div>
              <label htmlFor="username" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                What should we call you?
              </label>
              <input
                id="username"
                name="username"
                type="text"
                required
                className="mt-1 block w-full px-4 py-3 bg-white dark:bg-black border border-gray-300 dark:border-gray-700 rounded-xl focus:ring-2 focus:ring-[#5BFF8F] focus:border-transparent transition-all duration-200"
                placeholder="Elvin Atwine"
              />
            </div>

            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                Password
              </label>
              <input
                id="password"
                name="password"
                type="password"
                required
                className="mt-1 block w-full px-4 py-3 bg-white dark:bg-black border border-gray-300 dark:border-gray-700 rounded-xl focus:ring-2 focus:ring-[#5BFF8F] focus:border-transparent transition-all duration-200"
                placeholder="••••••••"
              />
            </div>
          </div>

          <div className="flex justify-center">
            <ReCAPTCHA 
              sitekey="6LfG2bIpAAAAAJrXdAnO_tOYO9OYmpQOHreiCr0i" 
              onChange={(value) => setCaptchaValue(value)}
              theme="light"
            />
          </div>

          {errorMessage && (
            <motion.div 
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="text-red-500 text-center text-sm"
            >
              {errorMessage}
            </motion.div>
          )}

          {verificationMessage && (
            <motion.div 
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="text-green-500 text-center text-sm"
            >
              {verificationMessage}
            </motion.div>
          )}

          <motion.button
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            type="submit"
            className="w-full flex justify-center py-3 px-4 border border-transparent rounded-xl shadow-sm text-lg font-medium text-black dark:text-white bg-[#CBFF5B] hover:bg-[#BBEF4B] dark:bg-[#5BFF8F] dark:hover:bg-[#4BEF7F] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#5BFF8F] transition-all duration-200"
          >
            Create account
          </motion.button>
        </form>

        <div className="flex justify-center space-x-4 text-sm text-gray-600 dark:text-gray-400">
          <a href="/tos" className="hover:text-gray-900 dark:hover:text-gray-200 transition-colors">Terms of Service</a>
          <span>•</span>
          <a href="/privacy-policy" className="hover:text-gray-900 dark:hover:text-gray-200 transition-colors">Privacy Policy</a>
        </div>
      </motion.div>
    </div>
  );
}
