import React from 'react';
import { motion } from 'framer-motion';
import mixpanel from 'mixpanel-browser';
import { useNavigate } from 'react-router-dom';

const KlorahBanner = () => {
  const navigate = useNavigate();

  const handleCTAClick = (location) => {
    mixpanel.track('Blog CTA Click', {
      location: location,
      source: window.location.pathname,
      postId: window.location.pathname.split('/').pop(),
      type: 'Sign Up'
    });
    navigate('/signup');
  };

  return (
    <motion.div 
      className="mx-auto px-4 py-4 mt-8" 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div className="flex justify-center">
        <div className="w-full max-w-3xl rounded-xl bg-[#E8871E] text-white p-6 shadow-lg ring-1 ring-gray-900/10 text-center">
          <h1 className="text-3xl font-bold mb-2">Spend less time managing clients</h1>
          <p className="text-lg mb-4">Join Klorah today and streamline your client billing with AI-driven solutions!</p>
          <div className="flex justify-center">
            <button
              type="button"
              className="rounded-md bg-gray-900 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
              onClick={() => handleCTAClick('banner')}
            >
              Join Now
            </button>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default KlorahBanner;
