import React, { useState, useEffect, useMemo } from 'react';
import { createClient } from 'contentful';
import { useNavigate } from 'react-router-dom';
import { motion, useScroll, useTransform, useMotionValue, useVelocity, useSpring, useAnimationFrame } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoice, faFileContract, faUserCircle, faBriefcase } from '@fortawesome/free-solid-svg-icons';
import LoadingPage from '../Dashboard/Agreement/loadingPage';
import NavigationBar from '../LandingPage/NavigationBar';
import Footer from '../LandingPage/Footer';
import mixpanel from 'mixpanel-browser';

const client = createClient({
  space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
  environment: 'master',
  accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
});

const headerTexts = [
  'Achieve Freelancing Freedom',
  'Advance Your Career',
  'Find Success Inspiration',
  'Perfect Freelancing Skills',
];

const wrap = (min, max, v) => {
  const rangeSize = max - min;
  return ((((v - min) % rangeSize) + rangeSize) % rangeSize) + min;
};

const ParallaxContainer = ({ children, baseVelocity = 20 }) => {
  const baseX = useMotionValue(0);
  const { scrollY } = useScroll();
  const scrollVelocity = useVelocity(scrollY);
  const smoothVelocity = useSpring(scrollVelocity, {
    damping: 50,
    stiffness: 400
  });
  const velocityFactor = useTransform(smoothVelocity, [0, 1000], [0, 5], {
    clamp: false
  });

  const x = useTransform(baseX, (v) => `${wrap(-100, 0, v)}%`);

  useAnimationFrame((t, delta) => {
    let moveBy = baseVelocity * (delta / 1000);
    baseX.set(baseX.get() + moveBy);
  });

  return (
    <div className="parallax-container overflow-hidden whitespace-nowrap">
      <motion.div className="parallax-scroller" style={{ x }}>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
      </motion.div>
    </div>
  );
};

function BlogLandingPage() {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortOption, setSortOption] = useState('date');
  const [currentHeaderIndex, setCurrentHeaderIndex] = useState(0);
  const [typedText, setTypedText] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    // Track blog landing page view
    mixpanel.track('Blog Page View', {
      page: 'Blog Landing',
      source: document.referrer
    });

    // Track time spent
    const startTime = Date.now();
    return () => {
      const timeSpent = Math.round((Date.now() - startTime) / 1000);
      mixpanel.track('Blog Page Exit', {
        page: 'Blog Landing',
        timeSpentSeconds: timeSpent
      });
    };
  }, []);

  useEffect(() => {
    client
      .getEntries({
        content_type: 'blogPage',
        order: '-sys.createdAt',
        include: 2
      })
      .then((response) => {
        console.log('Contentful Response:', JSON.stringify(response.items, null, 2));
        console.log('First post image:', response.items[0]?.fields?.image);
        setPosts(response.items);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching posts:', error);
        setError('Failed to load blog posts. Please try again later.');
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    let currentIndex = 0;
    const currentText = headerTexts[currentHeaderIndex];

    const typingInterval = setInterval(() => {
      setTypedText(currentText.slice(0, currentIndex));
      currentIndex++;

      if (currentIndex > currentText.length) {
        clearInterval(typingInterval);
        setTimeout(() => {
          setCurrentHeaderIndex((prevIndex) => (prevIndex + 1) % headerTexts.length);
        }, 2000);
      }
    }, 100);

    return () => clearInterval(typingInterval);
  }, [currentHeaderIndex]);

  const sortedPosts = useMemo(() => {
    return [...posts].sort((a, b) => {
      if (sortOption === 'date') {
        return new Date(b.sys.createdAt) - new Date(a.sys.createdAt);
      } else {
        return a.fields.title.localeCompare(b.fields.title);
      }
    });
  }, [posts, sortOption]);

  const handlePostClick = (post) => {
    mixpanel.track('Blog Post Click', {
      postId: post.sys.id,
      postTitle: post.fields.title,
      source: 'Blog Landing'
    });
    navigate(`/blog/${post.sys.id}`);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen bg-white dark:bg-black">
        <LoadingPage />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-screen bg-white dark:bg-black">
        <p className="text-red-500 dark:text-red-400 text-xl">{error}</p>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-white dark:bg-black">
      <NavigationBar />
      <div className="container mx-auto px-4 py-8">
        <div className="text-center mb-8">
          <h1 className="text-5xl font-bold">Welcome to Klorah Insights</h1>
          <h2 className="text-2xl mt-4 min-h-[2.5rem]">
            {typedText}
            <span className="animate-pulse">|</span>
          </h2>
        </div>
        <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
          {sortedPosts.map((post) => {
            console.log('Post data:', post);
            return (
              <motion.div
                key={post.sys.id}
                whileHover={{ scale: 1.05 }}
                transition={{ type: "spring", stiffness: 400, damping: 10 }}
                onClick={() => handlePostClick(post)}
                className="flex flex-col border p-4 bg-white dark:bg-[#161315] rounded-xl shadow-md cursor-pointer"
              >
                {post.fields.image && post.fields.image.fields?.file?.url ? (
                  <>
                    {console.log('Image URL:', post.fields.image.fields.file.url)}
                    <img
                      src={`https:${post.fields.image.fields.file.url}`}
                      alt={post.fields.title}
                      className="mb-4 w-full h-48 object-cover rounded-lg"
                      onError={(e) => {
                        console.error('Image failed to load:', e);
                        e.target.style.display = 'none';
                      }}
                    />
                  </>
                ) : (
                  <div className="mb-4 w-full h-48 bg-gray-200 flex items-center justify-center rounded-lg">
                    <span className="text-gray-500">No Image Available</span>
                  </div>
                )}
                <div className="mb-4">
                  <h2 className="text-2xl font-bold">{post.fields.title}</h2>
                </div>
                <div className="flex-grow">
                  <p className="text-gray-700 dark:text-gray-300 line-clamp-3">
                    {post.fields.body?.content[0]?.content[0]?.value || "No preview available."}
                  </p>
                </div>
                <div className="mt-auto">
                  <span className="text-[#8B9A46] hover:underline">
                    Read more
                  </span>
                </div>
              </motion.div>
            );
          })}
        </div>
      </div>
      <ParallaxContainer>Design • Create • Inspire • Thrive • Design • Create • Inspire • Thrive</ParallaxContainer>
      <Footer />
    </div>
  );
}

export default BlogLandingPage;
